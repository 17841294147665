import React, { FC, useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { navigate } from 'gatsby';

import { ICustomSearchComponentProps } from './model';
import 'components/CustomSearch/CustomSearch.scss';

const CustomSearch: FC<ICustomSearchComponentProps> = ({
  mainTitle,
  title,
  subtitle,
  keyWord,
  placeholder,
  hasSearchResults,
  resultsCount,
  searchPath,
}) => {
  const [searchValue, setSearchValue] = useState<string>(keyWord);

  const handleRedirect = useCallback(() => {
    if (!searchValue) {
      return;
    }

    navigate(`${searchPath || '/search'}?query=${searchValue}`);
  }, [searchValue]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      handleRedirect();
    },
    [handleRedirect]
  );

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);

  return (
    <div className="custom-search">
      {mainTitle ? <h1>{mainTitle}</h1> : null}
      <strong className="custom-search__title">
        {hasSearchResults ? `${resultsCount} ` : ''}
        {title}
        <span className="custom-search__key-word">
          &quot;
          {decodeURI(keyWord)}
          &quot; &#58;
        </span>
      </strong>
      {subtitle ? <span className="custom-search__subtitle">{subtitle}</span> : null}
      <Form className="form" onSubmit={handleSubmit}>
        <div className="search-input-holder">
          <FormControl
            type="text"
            placeholder={placeholder}
            className="search-input"
            defaultValue={hasSearchResults ? decodeURI(keyWord) : ''}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            aria-label={placeholder || subtitle}
          />
          <div className="btn-holder">
            <button
              type="submit"
              className="search-btn"
              onClick={handleRedirect}
              aria-label="Search"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CustomSearch;
