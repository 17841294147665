import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IUsefulLinksComponentProps } from './model';
import './UsefulLinks.scss';

const UsefulLinks: FC<IUsefulLinksComponentProps> = ({ title, listItem }) => {
  return (
    <div className="useful-links">
      <strong className="useful-links__title">{title}</strong>
      <ul className="useful-links__list">
        {listItem.map(({ url, name }) => (
          <li key={url}>
            <Link to={url || '/'} aria-label={name}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsefulLinks;
